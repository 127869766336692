import React from "react";
import ReactDOM from "react-dom";
import { WrappedIntlProvider } from "./react-components/wrapped-intl-provider";
import registerTelemetry from "./telemetry";
import Store from "./storage/store";
import "./utils/theme";
import { HomePage } from "./react-components/home/HomePage";
import { AuthContextProvider } from "./react-components/auth/AuthContext";
import "./react-components/styles/global.scss";
import { ThemeProvider } from "./react-components/styles/theme";

registerTelemetry("/home", "Hubs Home Page");

const store = new Store();
window.APP = { store };

const delay = ms => new Promise(res => setTimeout(res, ms));
const fadeOut = async (values) => {
	var transition = document.getElementById("transition");
    await delay(1000);
    transition.style.opacity = 0;
    await delay(1000);
    transition.style.zIndex = -1000;
};

function Root() {
  return (
    <WrappedIntlProvider>
      <ThemeProvider store={store}>
        <AuthContextProvider store={store}>
          <HomePage />
        </AuthContextProvider>
      </ThemeProvider>
    </WrappedIntlProvider>
  );
}

var osChecked = false;
var isMobile = false;
var isVR = false;

if (!osChecked){

  // Debug
  console.log(window);
  console.log(window.navigator.appVersion);

  // iPhone
  if (window.navigator.appVersion.includes("iPhone")){
    isMobile = true;
    console.log("iPhone Detected");

  // iPad
  } else if (window.navigator.appVersion.includes("iPad")){
    isMobile = true;
    console.log("iPad Detected");

  // Oculus
  } else if (window.navigator.appVersion.includes("Oculus")){
    isMobile = true;
    isVR = true;
    console.log("Oculus Detected");

  // Android (Less consistent, so we assume Android if not Windows, Mac, or iOS)
  } else if (!window.navigator.appVersion.includes("Windows") && !window.navigator.appVersion.includes("Mac")){
    isMobile = true;
    console.log("Android Detected");

  // Windows
  } else if (window.navigator.appVersion.includes("Windows")) {
    console.log("Windows Detected");

  // Mac
  } else if (window.navigator.appVersion.includes("Mac")) {
    console.log("Mac Detected");

  // Unknown
  } else {
    console.log("Couldn't Detect OS Based on Current Code :(");
  }
  osChecked = true;
}

ReactDOM.render(<Root />, document.getElementById("home-root"));

window.onload = function() {

	var bannerHeight = document.getElementById("banner").clientHeight;
	var titleBlock = document.getElementById("titleBlock");
	if (!isMobile || isVR){
		titleBlock.style.height = "calc(45vh - "+bannerHeight+"px)";
	}

	var titleBlockParent = document.getElementById("titleBlockParent");
	if (!isMobile || isVR){
		titleBlockParent.style.height = "45vh";
	}

	var header = document.getElementsByTagName("header")[0];

	var logo = document.getElementById("logo");
	logo.style.height = "clamp(0px, " + bannerHeight + "px, 200px)";
	logo.style.left = "calc(50% - "+0.5*logo.clientWidth+"px)";
	
	logo.style.top = header.clientHeight+(bannerHeight*.5-logo.clientHeight*.5) + "px";
}

window.onresize = function() {
	var bannerHeight = document.getElementById("banner").clientHeight;
	var titleBlock = document.getElementById("titleBlock");
	titleBlock.style.height = "calc(45vh - "+bannerHeight+"px)";

	var header = document.getElementsByTagName("header")[0];

	var logo = document.getElementById("logo");
	logo.style.height = "clamp(0px, " + bannerHeight + "px, 200px)";
	logo.style.left = "calc(50% - "+0.5*logo.clientWidth+"px)";

	logo.style.top = header.clientHeight+(bannerHeight*.5-logo.clientHeight*.5) + "px";
}

Promise.all(Array.from(document.images).filter(img => !img.complete).map(img => new Promise(resolve => { img.onload = img.onerror = resolve; }))).then(() => {
    console.log('images finished loading');
    fadeOut();
});