// Certain locales are duplicates such as "zh-cn" and "zh" or "en-us" and "en".
// For non "en" (since it's the default), use this mapping to define a fallback,
// which will only be used if the specified locale file doesn't exist.

export const AVAILABLE_LOCALES = {
  en: "English",
  "zh-cn": "简体中文",
  pt: "Portugês (Brasil)",
  ja: "日本語",
  es: "Español",
  cr: "Kreyòl ayisyen",
  ru: "Pусский",
  fr: "Français",
  ko: "한국어",
  de: "Deutsch",
  ar: "عربي",
  he: "עִברִית",
  "zh-tw": "繁體中文"
};

export const FALLBACK_LOCALES = {
  "zh-cn": "zh-cn",
  "zh-hans": "zh-cn",
  "zh-hans-cn": "zh-cn",
  jp: "ja"
};
