import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import styles from "./Footer.scss";
import discordLogoUrl from "../../assets/images/discord-logo-small.png";
import { Container } from "./Container";
import homeStyles from "../home/HomePage.scss";
import configs from "../../utils/configs";
import { Column } from "../layout/Column";
import { Button } from "../input/Button";
import { PWAButton } from "../home/PWAButton";

var osChecked = false;
var isMobile = false;

var lastUpdate = "Last Update: 1/16/23";
if (window.clientInformation.language == "fr"){ 
  lastUpdate = "Dernière mise à jour : 16/1/23";
}

if (!osChecked){

  // Debug
  console.log(window);
  console.log(window.navigator.appVersion);

  // iPhone
  if (window.navigator.appVersion.includes("iPhone")){
    isMobile = true;
    console.log("iPhone Detected");

  // iPad
  } else if (window.navigator.appVersion.includes("iPad")){
    isMobile = true;
    console.log("iPad Detected");

  // Oculus
  } else if (window.navigator.appVersion.includes("Oculus")){
    isMobile = true;
    console.log("Oculus Detected");

  // Android (Less consistent, so we assume Android if not Windows, Mac, or iOS)
  } else if (!window.navigator.appVersion.includes("Windows") && !window.navigator.appVersion.includes("Mac")){
    isMobile = true;
    console.log("Android Detected");

  // Windows
  } else if (window.navigator.appVersion.includes("Windows")) {
    console.log("Windows Detected");

  // Mac
  } else if (window.navigator.appVersion.includes("Mac")) {
    console.log("Mac Detected");

  // Unknown
  } else {
    console.log("Couldn't Detect OS Based on Current Code :(");
  }
  osChecked = true;
}


export function Footer({
  hidePoweredBy,
  showWhatsNewLink,
  showTerms,
  termsUrl,
  showPrivacy,
  privacyUrl,
  showCompanyLogo,
  companyLogoUrl,
  showDiscordBotLink,
  appName,
  isHmc
}) {
  return (
    <footer style={{display: "flex"}}>

      <div style={{display: "flex", alignItems: "center", height: "100%", marginLeft: "10px"}}>
        <Button sm preset="primary" as="a" href="/link" style={{height: "3.5vh", minHeight: "0px", fontSize: "clamp(9px, 1vw, 16px)", paddingTop: "0vh", fontWeight: "300"}}>
          <FormattedMessage id="home-page.have-code" defaultMessage="Have a room code?" />
        </Button>

        {!isMobile && (
          <div style={{display: "flex", alignItems: "center", height: "100%", marginLeft: "10px", marginRight: "10px"}}>
              {lastUpdate}
          </div>
        )}

        {isMobile && (
          <div style={{display: "flex", alignItems: "center", height: "100%", marginLeft: "10px", marginRight: "10px", fontSize: "8pt"}}>
              {lastUpdate}
          </div>
        )}

      </div>

      <div style={{display: "flex", alignItems: "center", height: "100%", marginLeft: "auto", marginRight: "10px"}}>
        <PWAButton/>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  hidePoweredBy: PropTypes.bool,
  showWhatsNewLink: PropTypes.bool,
  showTerms: PropTypes.bool,
  termsUrl: PropTypes.string,
  showPrivacy: PropTypes.bool,
  privacyUrl: PropTypes.string,
  showCompanyLogo: PropTypes.bool,
  companyLogoUrl: PropTypes.string,
  showDiscordBotLink: PropTypes.bool,
  appName: PropTypes.string,
  isHmc: PropTypes.bool
};
