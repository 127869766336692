
import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog } from "@fortawesome/free-solid-svg-icons/faCog";
import maskEmail from "../../utils/mask-email";
import styles from "./Header.scss";
import { Container } from "./Container";
import { SocialBar } from "../home/SocialBar";
import { SignInButton } from "../home/SignInButton";
import { AppLogo } from "../misc/AppLogo2";

export function Header({
  showCloud,
  enableSpoke,
  editorName,
  showDocsLink,
  docsUrl,
  showSourceLink,
  showCommunityLink,
  communityUrl,
  isAdmin,
  isSignedIn,
  email,
  onSignOut,
  isHmc
}) {
  return (
    <header style={{height: "5vh", borderBottom: "2px solid #1ab4fe", display: "flex"}}>

      <AppLogo forceConfigurableLogo style={{height: "3vh", marginTop: "1vh", marginLeft: "1vh", display: "block", float: "left"}}/>

      <div style={{display: "flex", height: "100%", fontSize: "clamp(0px, 3vw, 16px)", alignItems: "center", marginLeft: "auto", marginRight: "10px"}}>
        {isSignedIn ? (
          <div>
            <span>
              <FormattedMessage
                id="header.signed-in-as"
                defaultMessage="Signed in as {email}"
                values={{ email: maskEmail(email) }}
              />
            </span>{" "}
            <a style={{color: "#1ab4fe"}} href="#" onClick={onSignOut}>
              <FormattedMessage id="header.sign-out" defaultMessage="Sign Out" />
            </a>
          </div>
        ) : (
          <a style={{color: "#1ab4fe"}} href="/signin" rel="noreferrer noopener">
            <FormattedMessage id="header.sign-in" defaultMessage="Sign In" />
          </a>
        )}
      </div>

    </header>
  );
}

Header.propTypes = {
  showCloud: PropTypes.bool,
  enableSpoke: PropTypes.bool,
  editorName: PropTypes.string,
  showDocsLink: PropTypes.bool,
  docsUrl: PropTypes.string,
  showSourceLink: PropTypes.bool,
  showCommunityLink: PropTypes.bool,
  communityUrl: PropTypes.string,
  isAdmin: PropTypes.bool,
  isSignedIn: PropTypes.bool,
  email: PropTypes.string,
  onSignOut: PropTypes.func,
  isHmc: PropTypes.bool
};