import React, { useContext, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import classNames from "classnames";
import configs from "../../utils/configs";
import { CreateRoomButton } from "./CreateRoomButton";
import { PWAButton } from "./PWAButton";
import { useFavoriteRooms } from "./useFavoriteRooms";
import { usePublicRooms } from "./usePublicRooms";
import styles from "./HomePage.scss";
import { AuthContext } from "../auth/AuthContext";
import { createAndRedirectToNewHub } from "../../utils/phoenix-utils";
import { MediaGrid } from "../room/MediaGrid";
import { MediaTile } from "../room/MediaTiles";
import { PageContainer } from "../layout/PageContainer";
import { scaledThumbnailUrlFor } from "../../utils/media-url-utils";
import { Column } from "../layout/Column";
import { Button } from "../input/Button";
import { Container } from "../layout/Container";

import LandingPage from "../backpack/landing-page.js";

//Backpack Landing Page
import backpackStyles from "../backpack/stylesheets/landing-page.scss";

import { SocialBar } from "../home/SocialBar";
import { SignInButton } from "./SignInButton";
import { AppLogo } from "../misc/AppLogo";
import { isHmc } from "../../utils/isHmc";
import maskEmail from "../../utils/mask-email";

import banner from "../../assets/custom/svg/CLOUDS-BANNER2.svg?url";
import logo from "../../assets/custom/png/EQLAB-LOGO-WHITE.png?url";

//Test
// import ErrorCatch from "../ErrorCatch.js";

var osChecked = false;
var isMobile = false;
var isVR = false;
var testing = false;

// var slogan = "Welcome to EQLab’s Demo Server";
var slogan = "Welcome to Yookan";
if (window.clientInformation.language == "fr"){ 
  slogan = "Bienvenue à Yookan!";
}

// Platform Detection
if (!osChecked){

  // Debug
  console.log(window);
  console.log(window.navigator.appVersion);

  var appVersion = window.navigator.appVersion;

  // iPhone
  if (appVersion.includes("iPhone")){
    isMobile = true;
    console.log("iPhone Detected");

  // iPad
  } else if (appVersion.includes("iPad")){
    isMobile = true;
    console.log("iPad Detected");

  // Oculus
  } else if (appVersion.includes("Oculus")){
    isMobile = true;
    isVR = true;
    console.log("Oculus Detected");

  // Windows
  } else if (appVersion.includes("Windows")) {
    console.log("Windows Detected");

  // Mac
  } else if (appVersion.includes("Mac")) {
    console.log("Mac Detected");

  // Unknown
  } else {
    isMobile = true;
    console.log("Couldn't Detect OS - Assuming Android");
  }
  osChecked = true;
}

export function HomePage() {

  const auth = useContext(AuthContext);
  const intl = useIntl();

  const { results: favoriteRooms } = useFavoriteRooms();
  const { results: publicRooms } = usePublicRooms();

  const sortedFavoriteRooms = Array.from(favoriteRooms).sort((a, b) => b.member_count - a.member_count);
  const sortedPublicRooms = Array.from(publicRooms).sort((a, b) => b.member_count - a.member_count);
  const wrapInBold = chunk => <b>{chunk}</b>;
  useEffect(() => {
    const qs = new URLSearchParams(location.search);

    // Support legacy sign in urls.
    if (qs.has("sign_in")) {
      const redirectUrl = new URL("/signin", window.location);
      redirectUrl.search = location.search;
      window.location = redirectUrl;
    } else if (qs.has("auth_topic")) {
      const redirectUrl = new URL("/verify", window.location);
      redirectUrl.search = location.search;
      window.location = redirectUrl;
    }

    if (qs.has("new")) {
      createAndRedirectToNewHub(null, null, true, null);
    }
  }, []);

  const canCreateRooms = !configs.feature("disable_room_creation") || auth.isAdmin;
  const email = auth.email;
  return (

    <>

    {/*testing && <ErrorCatch/>*/}

    {isMobile && !isVR &&
      <PageContainer style={{height: "90vh"}}>
        <div id="mobileTitleBlockParent" style={{height: "20vh"}}>
          <div style={{display: "flex", height: "8vh"}}>
            <div style={{width: "100%"}}>
              <img
                alt={intl.formatMessage(
                  {
                    id: "home-page.hero-image-alt",
                    defaultMessage: "Screenshot of {appName}"
                  },
                  { appName: configs.translation("app-name") }
                )}
                src={banner}
                style={{width: "100%"}}
                id="banner"
              />
              <img
                src={logo}
                style={{position: "sticky", marginTop: "-10vh"}}
                id="logo"
              />
            </div>
          </div>

          <div id="mobileTitle" style={{display: "table", marginLeft: "auto", marginRight: "auto", height: "12vh", width: "75%"}}>
            <h1 style={{fontSize: "14pt", fontWeight: "100", lineHeight: "20pt", textAlign: "center", display: "table-cell", verticalAlign: "middle"}}>{slogan}</h1>
          </div>

        </div>
        {configs.feature("show_feature_panels") && (
          <Container className={classNames(styles.features, styles.colLg, styles.centerLg)}>
            <Column padding gap="xl" className={styles.card}>
              <img src={configs.image("landing_rooms_thumb")} />
              <h3>
                <FormattedMessage id="home-page.rooms-title" defaultMessage="Instantly create rooms" />
              </h3>
              <p>
                <FormattedMessage
                  id="home-page.rooms-blurb"
                  defaultMessage="Share virtual spaces with your friends, co-workers, and communities. When you create a room with Hubs, you’ll have a private virtual meeting space that you can instantly share <b>- no downloads or VR headset necessary.</b>"
                  values={{ b: wrapInBold }}
                />
              </p>
            </Column>
            <Column padding gap="xl" className={styles.card}>
              <img src={configs.image("landing_communicate_thumb")} />
              <h3>
                <FormattedMessage id="home-page.communicate-title" defaultMessage="Communicate and Collaborate" />
              </h3>
              <p>
                <FormattedMessage
                  id="home-page.communicate-blurb"
                  defaultMessage="Choose an avatar to represent you, put on your headphones, and jump right in. Hubs makes it easy to stay connected with voice and text chat to other people in your private room."
                />
              </p>
            </Column>
            <Column padding gap="xl" className={styles.card}>
              <img src={configs.image("landing_media_thumb")} />
              <h3>
                <FormattedMessage id="home-page.media-title" defaultMessage="An easier way to share media" />
              </h3>
              <p>
                <FormattedMessage
                  id="home-page.media-blurb"
                  defaultMessage="Share content with others in your room by dragging and dropping photos, videos, PDF files, links, and 3D models into your space."
                />
              </p>
            </Column>
          </Container>
        )}
        {sortedPublicRooms.length > 0 && (
          <Container className={styles.roomsContainer}>
            <h3 className={styles.roomsHeading}>
              <FormattedMessage id="home-page.public--rooms" defaultMessage="Public Rooms" />
            </h3>
            <Column grow padding className={styles.rooms}>
              <MediaGrid center>
                {sortedPublicRooms.map(room => {
                  return (
                    <MediaTile
                      key={room.id}
                      entry={room}
                      processThumbnailUrl={(entry, width, height) =>
                        scaledThumbnailUrlFor(entry.images.preview.url, width, height)
                      }
                    />
                  );
                })}
              </MediaGrid>
            </Column>
          </Container>
        )}
        {sortedFavoriteRooms.length > 0 && (
          <Container className={styles.roomsContainer}>
            <h3 className={styles.roomsHeading}>
              <FormattedMessage id="home-page.favorite-rooms" defaultMessage="Favorite Rooms" />
            </h3>
            <Column grow padding className={styles.rooms}>
              <MediaGrid center>
                {sortedFavoriteRooms.map(room => {
                  return (
                    <MediaTile
                      key={room.id}
                      entry={room}
                      processThumbnailUrl={(entry, width, height) =>
                        scaledThumbnailUrlFor(entry.images.preview.url, width, height)
                      }
                    />
                  );
                })}
              </MediaGrid>
            </Column>
          </Container>
        )}
        <LandingPage/>
      </PageContainer>
    }

    {(!isMobile || isVR) &&
      <PageContainer style={{height: "90vh"}}>
        <div id="titleBlockParent">
          <div style={{display: "flex"}}>
            <div style={{width: "100%"}}>
              <img
                alt={intl.formatMessage(
                  {
                    id: "home-page.hero-image-alt",
                    defaultMessage: "Screenshot of {appName}"
                  },
                  { appName: configs.translation("app-name") }
                )}
                src={banner}
                style={{width: "100%"}}
                id="banner"
              />
              {!testing && 
                <img
                  src={logo}
                  style={{position: "absolute"}}
                  id="logo"
                />
              }
            </div>
          </div>

          <div id="titleBlock" style={{display: "table", marginLeft: "auto", marginRight: "auto"}}>
            <h1 style={{fontSize: "2em", fontWeight: "100", textAlign: "center", display: "table-cell", verticalAlign: "middle"}}>{slogan}</h1>          
          </div>

        </div>
        {configs.feature("show_feature_panels") && (
          <Container className={classNames(styles.features, styles.colLg, styles.centerLg)}>
            <Column padding gap="xl" className={styles.card}>
              <img src={configs.image("landing_rooms_thumb")} />
              <h3>
                <FormattedMessage id="home-page.rooms-title" defaultMessage="Instantly create rooms" />
              </h3>
              <p>
                <FormattedMessage
                  id="home-page.rooms-blurb"
                  defaultMessage="Share virtual spaces with your friends, co-workers, and communities. When you create a room with Hubs, you’ll have a private virtual meeting space that you can instantly share <b>- no downloads or VR headset necessary.</b>"
                  values={{ b: wrapInBold }}
                />
              </p>
            </Column>
            <Column padding gap="xl" className={styles.card}>
              <img src={configs.image("landing_communicate_thumb")} />
              <h3>
                <FormattedMessage id="home-page.communicate-title" defaultMessage="Communicate and Collaborate" />
              </h3>
              <p>
                <FormattedMessage
                  id="home-page.communicate-blurb"
                  defaultMessage="Choose an avatar to represent you, put on your headphones, and jump right in. Hubs makes it easy to stay connected with voice and text chat to other people in your private room."
                />
              </p>
            </Column>
            <Column padding gap="xl" className={styles.card}>
              <img src={configs.image("landing_media_thumb")} />
              <h3>
                <FormattedMessage id="home-page.media-title" defaultMessage="An easier way to share media" />
              </h3>
              <p>
                <FormattedMessage
                  id="home-page.media-blurb"
                  defaultMessage="Share content with others in your room by dragging and dropping photos, videos, PDF files, links, and 3D models into your space."
                />
              </p>
            </Column>
          </Container>
        )}
        {sortedPublicRooms.length > 0 && (
          <Container className={styles.roomsContainer}>
            <h3 className={styles.roomsHeading}>
              <FormattedMessage id="home-page.public--rooms" defaultMessage="Public Rooms" />
            </h3>
            <Column grow padding className={styles.rooms}>
              <MediaGrid center>
                {sortedPublicRooms.map(room => {
                  return (
                    <MediaTile
                      key={room.id}
                      entry={room}
                      processThumbnailUrl={(entry, width, height) =>
                        scaledThumbnailUrlFor(entry.images.preview.url, width, height)
                      }
                    />
                  );
                })}
              </MediaGrid>
            </Column>
          </Container>
        )}
        {sortedFavoriteRooms.length > 0 && (
          <Container className={styles.roomsContainer}>
            <h3 className={styles.roomsHeading}>
              <FormattedMessage id="home-page.favorite-rooms" defaultMessage="Favorite Rooms" />
            </h3>
            <Column grow padding className={styles.rooms}>
              <MediaGrid center>
                {sortedFavoriteRooms.map(room => {
                  return (
                    <MediaTile
                      key={room.id}
                      entry={room}
                      processThumbnailUrl={(entry, width, height) =>
                        scaledThumbnailUrlFor(entry.images.preview.url, width, height)
                      }
                    />
                  );
                })}
              </MediaGrid>
            </Column>
          </Container>
        )}
        <LandingPage/>
      </PageContainer>
    }
    </>
  );
}