import React from "react";
import styles from "./stylesheets/landing-page.scss";

import { useContext, Component, useEffect } from "react";

import create_icon from "../../assets/custom/svg/CREATE_AVATAR_VISUAL2.svg";
import createWorld_icon from "../../assets/custom/svg/BUILD_WORLD_VISUAL2.svg";
import go_to_worlds from "../../assets/custom/svg/GO-TO-WORLD_VISUAL2.svg";

import { Button } from "../input/Button";
import { useCssBreakpoints } from "react-use-css-breakpoints";
import configs from "../../utils/configs";
import { CreateRoomButton } from "../home/CreateRoomButton";
import { AuthContext } from "../auth/AuthContext";
import { createAndRedirectToNewHub } from "../../utils/phoenix-utils";
import { FormattedMessage } from "react-intl";

var osChecked = false;
var isMobile = false;
var isVR = false;

export default function LandingPage() {
  useEffect(() => {
    const qs = new URLSearchParams(location.search);

    // Support legacy sign in urls.
    if (qs.has("sign_in")) {
      const redirectUrl = new URL("/signin", window.location);
      redirectUrl.search = location.search;
      window.location = redirectUrl;
    } else if (qs.has("auth_topic")) {
      const redirectUrl = new URL("/verify", window.location);
      redirectUrl.search = location.search;
      window.location = redirectUrl;
    }

    if (qs.has("new")) {
      createAndRedirectToNewHub(null, null, true, null);
    }
  }, []);
  const breakpoint = useCssBreakpoints();

  const canCreateRooms = configs.isAdmin();

  if (!osChecked){

    // Debug
    console.log(window);
    console.log(window.navigator.appVersion);

    // iPhone
    if (window.navigator.appVersion.includes("iPhone")){
      isMobile = true;
      console.log("iPhone Detected");

    // iPad
    } else if (window.navigator.appVersion.includes("iPad")){
      isMobile = true;
      console.log("iPad Detected");

    // Oculus
    } else if (window.navigator.appVersion.includes("Oculus")){
      isVR = true;
      isMobile = true;
      console.log("Oculus Detected");

    // Android (Less consistent, so we assume Android if not Windows, Mac, or iOS)
    } else if (!window.navigator.appVersion.includes("Windows") && !window.navigator.appVersion.includes("Mac")){
      isMobile = true;
      console.log("Android Detected");

    // Windows
    } else if (window.navigator.appVersion.includes("Windows")) {
      console.log("Windows Detected");

    // Mac
    } else if (window.navigator.appVersion.includes("Mac")) {
      console.log("Mac Detected");

    // Unknown
    } else {
      console.log("Couldn't Detect OS Based on Current Code :(");
    }
    osChecked = true;
  }

  return (

    <>
    {isMobile && !isVR &&

      <div className={styles.avatarSdk} style={{height: "70vh"}}>
        <>
        {canCreateRooms &&
          <div className={styles.mobileButtons}>
            <span className={styles.mobileSpan}>
              <div className={styles.mobileImgButton}>
                <div className={styles.mobileCrop}>
                  <img className={[styles.createAvatar_img, styles.mobileImg].join(' ')} src={create_icon} />
                </div>
                <Button
                  lg={breakpoint === "sm" || breakpoint === "md"}
                  xl={breakpoint !== "sm" && breakpoint !== "md"}
                  preset="primary"
                  onClick={e => {
                    window.sessionStorage.setItem("gender", null);
                    window.sessionStorage.setItem("inCustomizer", true);
                    window.sessionStorage.setItem("skipAvatarCreation", false);
                    window.sessionStorage.setItem("isMobile", isMobile);
                    window.APP.store.update({ preferences: { enableQuickJoin: false } });
                    e.preventDefault();
                    createAndRedirectToNewHub(null, null, false, null);
                  }}
                  className={styles.mobileButton}
                >
                  <FormattedMessage id="custom.create-avatar" defaultMessage="Create Your 3D Avatar" />
                </Button>
              </div>
            </span>
            <span className={styles.mobileSpan}>
              <div className={styles.mobileImgButton}>
                <div className={styles.mobileCrop}>
                  <img className={[styles.createWorld_img, styles.mobileImg].join(' ')} src={createWorld_icon} />
                </div>
                <Button
                  lg={breakpoint === "sm" || breakpoint === "md"}
                  xl={breakpoint !== "sm" && breakpoint !== "md"}
                  preset="primary"
                  onClick={e => {
                    window.location.href = '/spoke';
                  }}
                  className={styles.mobileButton}
                >
                  <FormattedMessage id="custom.create-world" defaultMessage="Create Your 3D World" />
                </Button>
              </div>
            </span>
            <span className={styles.mobileSpan}>
              <div className={styles.mobileImgButton}>
                <div className={styles.mobileCrop}>
                  <img className={[styles.createWorld_img, styles.mobileImg].join(' ')} src={go_to_worlds} />
                </div>
                <Button
                  lg={breakpoint === "sm" || breakpoint === "md"}
                  xl={breakpoint !== "sm" && breakpoint !== "md"}
                  preset="primary"
                  onClick={e => {
                    window.APP.store.update({ preferences: { enableQuickJoin: false } });
                    window.sessionStorage.setItem("micGranted", false);
                    e.preventDefault();
                    window.sessionStorage.setItem("returningVisitor", false);
                    window.sessionStorage.setItem("skipAvatarCreation", true);
                    window.sessionStorage.setItem("inCustomizer", false);
                    createAndRedirectToNewHub(null, null, false, null);
                  }}
                  className={styles.mobileButton}
                >
                  <FormattedMessage id="custom.create-room" defaultMessage="Go to 3D World" />
                </Button>
              </div>
            </span>
          </div>
        }
        </>

      </div>
    }

    {(!isMobile || isVR) &&
      <div className={styles.avatarSdk}>
        <>
        {canCreateRooms && (!isMobile || isVR) &&
          <div className={styles.buttons}>
            <span className={styles.desktopSpan}>
              <div className={styles.desktopImgButton}>
                <img className={[styles.createAvatar_img, styles.desktopImg].join(' ')} src={create_icon} />
                <Button
                  lg={breakpoint === "sm" || breakpoint === "md"}
                  xl={breakpoint !== "sm" && breakpoint !== "md"}
                  preset="primary"
                  onClick={e => {
                    window.sessionStorage.setItem("gender", null);
                    window.sessionStorage.setItem("inCustomizer", true);
                    window.sessionStorage.setItem("skipAvatarCreation", false);
                    // window.sessionStorage.setItem("isMobile", isMobile);
                    window.APP.store.update({ preferences: { enableQuickJoin: false } });
                    e.preventDefault();
                    createAndRedirectToNewHub(null, null, false, null);
                  }}
                  className={styles.desktopButton}
                >
                  <FormattedMessage id="custom.create-avatar" defaultMessage="Create Your 3D Avatar" />
                </Button>
              </div>
            </span>
            <span className={styles.desktopSpan}>
              <div className={styles.desktopImgButton}>
                <img className={[styles.createWorld_img, styles.desktopImg].join(' ')} src={createWorld_icon} />
                <Button
                  lg={breakpoint === "sm" || breakpoint === "md"}
                  xl={breakpoint !== "sm" && breakpoint !== "md"}
                  preset="primary"
                  onClick={e => {
                     window.location.href = '/spoke';
                  }}
                  className={styles.desktopButton}
                >
                  <FormattedMessage id="custom.create-world" defaultMessage="Create Your 3D World" />
                </Button>
              </div>
            </span>
            <span className={styles.desktopSpan}>
              <div className={styles.desktopImgButton}>
                <img className={[styles.createWorld_img, styles.desktopImg].join(' ')} src={go_to_worlds} />
                <Button
                  lg={breakpoint === "sm" || breakpoint === "md"}
                  xl={breakpoint !== "sm" && breakpoint !== "md"}
                  preset="primary"
                  onClick={e => {
                    window.APP.store.update({ preferences: { enableQuickJoin: false } });
                    window.sessionStorage.setItem("micGranted", false);
                    e.preventDefault();
                    window.sessionStorage.setItem("returningVisitor", false);
                    window.sessionStorage.setItem("skipAvatarCreation", true);
                    window.sessionStorage.setItem("inCustomizer", false);
                    createAndRedirectToNewHub(null, null, false, null);
                  }}
                  className={styles.desktopButton}
                >
                  <FormattedMessage id="custom.create-room" defaultMessage="Go to 3D World" />
                </Button>
              </div>
            </span>
            {/*
            <span>
              <div className={styles.desktopImgButton}>
                <img className={styles.createWorld_img} src={go_to_worlds} />
                {canCreateRooms && <CreateRoomButton />}
              </div>
            </span>
            */}
          </div>
        }
        </>
      </div>
    }
    </>

  );
}